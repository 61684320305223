.card {
  display: flex;
  padding: 25px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 5px 13px #081623;
  transition: 0.3s;
  background-color: #1b2326;
  text-shadow: 1px .75px #111;
  color: #fff;
  min-height: 45vh;
}

.section-header {
  background-color: transparent;
  padding: 2.5% 0;
  margin-bottom: 2%;
}

.section {
  width: 100%;
}

h1, h2, h3, h4 {
  margin: 0;
}

ul {
  padding: 0;
  display: inline-block;
  word-wrap: break-word;
  list-style-type: disc; /* Add bullets */
  margin-left: 20px; /* Indent bullets */
}

li {
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 10px;
}

a {
  color: #fff;
  text-decoration: none;
}

a:active {
  color: #111;
  text-decoration: none;
  cursor: pointer;
}

address {
  font-size: 16px;
  padding: 5% 0;
}

.address-container {
}

@media(max-width: 405px) {
  .card {
    flex-direction: column;
    align-items: flex-end;
  }

  .section-header {
    width: 85%;
    height: 20%;
    padding: 15%;
  }

  .card-container {
    padding: 5%;
    width: 80%;
  }

  ul {
    display: block;
  }
}
