.navbar {
    position: relative;
    top: 0;
    left: 0; 
    width: 100%;
    background-color: #081623;
    height: 56px;
    margin-top: 30px;
}

.navbar-navigation {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 1rem;
}

.navbar__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1.5rem;
}

.navbar__logo p {
    color: #fff;
    text-decoration: none;
    font-size: 32px;
}

.navbar__logo a {
    color: #fff;
    text-decoration: none;
    font-size: 32px;
}

.navbar__logo__image {
    width: 40px;
    margin-right: 10px;
}

.spacer {
    flex: 1;
}

.navbar__items ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.navbar__items li {
    padding: 0 0.5rem;
}

.navbar__items a {
    color: #fff;
    text-decoration: none;
}

.navbar__navigation a:hover,
.navbar__navigation a:active {
    color: #29B6F6
}

@media (max-width: 939px ) {
    .navbar__items {
        display: none;
    }

}

@media ( min-width: 941px ) {
    .navbar__toggle_button {
        display: none;
    }

    .navbar__logo {
        margin-left: 0;
    }
}

@media (max-width: 500px ) {
    .navbar {
        height: 80px;
    }
}