.side-drawer {
    height: 100%;
    background: #fff;
    box-shadow: 1px 0px 8px rgba(0, 0, 0, .5);
    position: fixed;
    top: 0;
    left: 0;
    width: 75%;
    max-width: 380px;
    z-index: 200;
    background-color: #081623;
    transform: translateX(-100%);
    transition: transform .4s ease-out;
}

.side-drawer.open {
    transform: translateX(0);
}

.side-drawer ul {
    height: 100%;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.side-drawer li {
    margin: .5rem 0;
}

.side-drawer a {
    color: #fff;
    font-size: 32px;
}

.side-drawer a:hover,
.side-drawer a:active {
    cursor: pointer;
}

@media (min-width: 940px) {
    .side-drawer {
        display: none;
    }
}