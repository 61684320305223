.projects-page {
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .projects-page h2 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .project-card {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
    margin: 10px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
  }
  
  .project-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
  
  .project-card h3 {
    font-size: 1.75rem;
    margin-bottom: 10px;
  }
  
  .project-card p {
    font-size: 1rem;
    line-height: 1.5;
    color: #333333;
    margin-bottom: 10px;
  }
  
  .project-card p strong {
    color: #0073e6;
  }
  
  @media (max-width: 768px) {
    .projects-page {
      padding: 10px;
    }
  
    .project-card {
      padding: 15px;
    }
  
    .project-card h3 {
      font-size: 1.5rem;
    }
  
    .project-card p {
      font-size: 0.875rem;
    }
  }
  