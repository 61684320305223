.hero-image {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../img/cardbackground3.jpg');
    height: 95vh;
    width: 100%;

    display: flex;
    flex-direction: column;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.hero-title {
    text-align: center;
    position: absolute;
    height: 40%;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
}

.hero-title h2 {
    font-size: 36px;
}

.hero-title p {
    font-size: 24px;
}

.hero-text {
    text-align: center;
    position: absolute;
    height: 50%;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -25%);
    color: #fff;
}


.call-to-action {
    display: block;
    margin: 5px 0%;
    border-radius: 5px;
    height: 60px;
    width: 360px;
    background-color: #081623;
    border: 1px solid #fff;
    padding: 0;
    box-sizing: border-box;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, .5);
}

.call-to-action:hover {
    cursor: pointer;
    box-shadow: 1px 2px 5px rgba(255, 255, 255, .5);
}

.call-to-action:focus {
    outline: none;
}

.call-to-action p {
    margin: 0;
    padding: 7.5px;
    color: #fff;
    text-decoration: none;
    color: #fff;
    font-size: 32px;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
}

h3 {
    font-size: 32px;
}

@media (max-width: 940px) {
    .hero-image{
        background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../img/background1.jpg');
        height: 100vh;
    }

    .hero-text{
        top: 50%;
        left: 50%;
    }

    .call-to-action {
        width: auto;
        height: auto;
    }
    
}