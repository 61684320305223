.main-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-around;
}

.main-container > * {
    margin: 15px 10px;
}

@media (min-width: 939px ) {
    .main-container {
        flex-direction: row;
        
    }

    .main-container > * {
        width: 30vw;
    }

}